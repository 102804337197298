import * as React from 'react';
import { connect } from "react-redux";
import MaterialAppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import IconButton from "@material-ui/core/IconButton/IconButton";

import LightBulbOff from '@material-ui/icons/Brightness5';
import LightBulbOn from '@material-ui/icons/Brightness7';

import { toggleDarkTheme } from "../actions/theme.action";
import styles from './AppBar.module.css';
import AppBarLogo from "./AppBarLogo";


class AppBar extends React.PureComponent {

    render() {
        const { darkTheme, toggleDarkTheme } = this.props;
        return <>
            <MaterialAppBar position='sticky'>
                <Toolbar disableGutters>
                    <AppBarLogo gutter/>
                    <div className={styles.grow} />
                    <IconButton color='inherit' onClick={toggleDarkTheme}>
                        {darkTheme ? <LightBulbOn /> : <LightBulbOff />}
                    </IconButton>
                </Toolbar>
            </MaterialAppBar>
        </>;
    }
}

const mapStoreToProps = store => ({
    darkTheme: store.theme.darkTheme
});

const mapDispatchToProps = dispatch => ({
    toggleDarkTheme: () => dispatch(toggleDarkTheme())
});

export default connect(mapStoreToProps, mapDispatchToProps)(AppBar);
