import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './Loader.module.css';

const Loader = ({size}) => {
    const style = size ? {
        width: size,
        height: size,
        marginTop: -size/2,
        marginLeft: -size/2
    } : null;
    return <CircularProgress className={styles.loader} style={style}/>
};

export default Loader;