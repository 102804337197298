import * as React from 'react';
import styles from './SelectorMap.module.css';
import Loader from "../Loader";
import connect from "react-redux/es/connect/connect";
import GMap from "./GMap";
import Dialog from "../Dialog";
import {updateBuilding} from "../../actions/building-details.action";
import Drawer from "@material-ui/core/Drawer/Drawer";
import SelectorMapHelper from "./SelectorMapHelper/SelectorMapHelper";

class SelectorMap extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            path: undefined,
            isAreaDefined: false
        }
    }

    render() {
        return <>
            <Dialog title="Select Building" onSave={this.handleSave} isAreaDefined={this.state.isAreaDefined}>
                <div className={styles.container}>
                    <div className={styles.helperContainer}>
                        <SelectorMapHelper isAreaDefined={this.state.isAreaDefined} />
                    </div>
                    <GMap
                        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3&key=AIzaSyDsXZuhEc3wv7a4LXej8IUAgVCHG4fVfnA&libraries=geometry,drawing,places"
                        loadingElement={<Loader/>}
                        containerElement={<div className={styles.mapContainer}/>}
                        mapElement={<div className={styles.map}/>}
                        onBuildingSelect={this.handleBuildingSelect}

                    />
                </div>
            </Dialog>
        </>;
    }

    renderDrawer() {
        return <Drawer
            variant="permanent"
            anchor="left"
            classes={{
                paper: styles.drawerPaper,
            }}
        >
            drawer
        </Drawer>
    }

    handleBuildingSelect = building => {
        this.setState({
            path: building.getPath()
        });

        this.checkIfAreaIsDefined();
    };

    handleSave = () => {
        const {path} = this.state;
        const area = window.google.maps.geometry.spherical.computeArea(path);
        const encodedPath = window.google.maps.geometry.encoding.encodePath(path);
        this.props.updateBuilding(path.getArray(), area, encodedPath);
    };

    checkIfAreaIsDefined = () => {
        if (this.state.path) {
            {
                this.setState({
                    isAreaDefined: true
                });
            }
        } else {
            {
                this.setState({
                    isAreaDefined: false
                });
            }
        }
    };
}

const mapDispatchToProps = dispatch => ({
    updateBuilding: (building, area, encodedPath) => dispatch(updateBuilding(building, area, encodedPath))
});

export default connect(null, mapDispatchToProps)(SelectorMap);
