import * as React from 'react';
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography/Typography";
import Link from "@material-ui/core/Link/Link";
import { connect } from "react-redux";

import styles from './Layout.module.css';

const Layout = ({ children, darkTheme }) => (
    <>
        <Paper className={styles.paper}>
            {children}
        </Paper>

        <div className={styles.bottomContainer}>
            <a href="https://bwk.kuleuven.be/bwf/" target='_blank' rel='noopener noreferrer'
                className={styles.logoContainer}>
                <img src='/images/kul.png' alt='Logo KUL' className={styles.logo} />
            </a>
            <a href="https://liquisol.com" target='_blank' rel='noopener noreferrer'
                className={styles.logoContainer}>
                <img src={getLiquisolLogo(darkTheme)} alt='Logo Liquisol' className={styles.logo} />
            </a>
            <a href="https://www.liquisol.com/sites/default/files/fraunhofer-2white_20181205.pdf"
                target='_blank' rel='noopener noreferrer' className={styles.logoContainer}>
                <img src={getFraunhoferLogo(darkTheme)} alt='Logo Fraunhofer' className={styles.logo} />
            </a>

            <Typography variant='h6' align='center' className={styles.notion}>
                <Link target='_blank' href='https://www.linkedin.com/in/iliasdewachter/' color='secondary'>
                    Made with Notion
                </Link>
            </Typography>
        </div>

        <div className={styles.grow} />
    </>
);

const getLiquisolLogo = (darkTheme) => {
    return darkTheme ? '/images/liquisol-dark.png' : '/images/liquisol.png';
};

const getFraunhoferLogo = (darkTheme) => {
    return darkTheme ? '/images/fraunhofer-dark.png' : '/images/fraunhofer.png';
};

const mapStateToProps = state => ({
    darkTheme: state.theme.darkTheme
});

export default connect(mapStateToProps)(Layout);