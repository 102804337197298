export default [
    {
        name: 'Londen',
        lat: 51.5286208,
        lon: -0.1716373
    },
    {
        name: 'Nancy',
        lat: 48.6880806,
        lon: 6.1646822
    },
    {
        name: 'Praag',
        lat: 50.0596486,
        lon: 14.3955853
    },
    {
        name: 'Madrid',
        lat: 40.4379332,
        lon: -3.7495755
    },
    {
        name: 'Riyadh',
        lat: 24.7251268,
        lon: 46.6824485
    }
]