import * as React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from "react-redux";
import thunk from 'redux-thunk';

import Theme from './Theme';
import combinedReducers from './reducers';
import AppBar from "./components/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import styles from './App.module.css';
import withStyles from "@material-ui/core/styles/withStyles";
import Routing from "./Routing";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from './pages/Layout';


const storeEnhancers = compose(
    applyMiddleware(thunk),
);
const store = createStore(combinedReducers, storeEnhancers);


const App = () => (
    <>
        <CssBaseline />
        <Provider store={store}>
            <Theme>
                <AppBar />
                <Router>
                    <Content />
                </Router>
            </Theme>
        </Provider>
    </>
);


const style = theme => ({
    content: {
        backgroundColor: theme.palette.background.default
    }
});
const Content = withStyles(style)(({ classes }) => (
    <div className={[styles.content, classes.content].join(' ')}>
        <Layout>
            <Routing />
        </Layout>
    </div>
));

export default App;
