import * as React from 'react';
import connect from "react-redux/es/connect/connect";
import {GoogleMap, withGoogleMap, withScriptjs} from "react-google-maps";
import darkStyles from "./dark-map";
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import SearchBox from "react-google-maps/lib/components/places/SearchBox";
import { withTheme } from '@material-ui/core/styles';
import styles from './GMap.module.css';

class GMap extends React.PureComponent {

    constructor() {
        super();
        this.searchRef = React.createRef();
        this.mapRef = React.createRef();
        this.state = {
            oldPolygon: undefined
        }
    }


    render() {
        const {darkTheme} = this.props;

        const drawingManagerOptions = {
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControlOptions: {
                drawingModes: ['polygon']
            },
            polygonOptions: {
                strokeColor: this.props.theme.palette.primary.main
            }
        };

        return (
            <GoogleMap
                defaultZoom={6}
                ref={this.mapRef}
                defaultCenter={{
                    lat: 48.8520549,
                    lng: 7.3160852
                }}
                mapTypeId={window.google.maps.MapTypeId.HYBRID}
                options={{
                    styles: darkTheme ? darkStyles : {},
                    disableDefaultUI: true,
                    mapTypeControl: true,
                    zoomControl: true,
                    tilt: 0
                }}
                clickableIcons
            >
                <DrawingManager defaultOptions={drawingManagerOptions}
                                onPolygonComplete={this.handleBuildingSelect}/>
                <SearchBox
                    controlPosition={window.google.maps.ControlPosition.TOP_RIGHT}
                    ref={this.searchRef}
                    onPlacesChanged={this.handlePlacesChanged}>
                    <input
                        id="gmap-input"
                        type="text"
                        placeholder="Search"
                        className={styles.search}
                    />
                </SearchBox>
            </GoogleMap>
        );
    }

    handleBuildingSelect = polygon => {
        if (this.state.oldPolygon) {
            this.state.oldPolygon.setMap(null);
        }
        this.setState({
            oldPolygon: polygon
        });

        this.props.onBuildingSelect(polygon);
    };

    handlePlacesChanged = () => {
        const places = this.searchRef.current.getPlaces();
        if (places.length === 0) return;

        const bounds = new window.google.maps.LatLngBounds();
        places.forEach(place => {
            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
        });

        this.mapRef.current.fitBounds(bounds);
    };
}

const mapStateToProps = state => ({
    darkTheme: state.theme.darkTheme
});

export default connect(mapStateToProps)(withTheme()((withScriptjs(withGoogleMap(GMap)))));
