import * as React from 'react';
import Dialog from "@material-ui/core/Dialog/Dialog";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import connect from "react-redux/es/connect/connect";
import {updateBuildingDetails} from "../../actions/building-details.action";
import Paper from "@material-ui/core/Paper/Paper";
import Link from "../../components/Link";
import styles from './RoofVariantDialog.module.css';
import Typography from "@material-ui/core/Typography/Typography";

const variants = [
    {
        variant: 1,
        title: 'Variant 1',
        wallDetails: <>
            Thickness = 17.5 cm
            <br/>
            U = 0.60 W/(m²K)
        </>,
        roofDetails: <>
            Thickness = 24 cm
            <br/>
            U = 0.44 W/(m²K)
        </>
    },
    {
        variant: 2,
        title: 'Variant 2 (default)',
        wallDetails: <>
            Sandwich panel (PUR-core between 2 steel cover sheets)
            <br/>
            Thickness = 8.4 cm
            <br/>
            U = 0.38 W/(m²K)
        </>,
        roofDetails: <>
            Sandwich panel (PUR-core between 2 steel cover sheets)
            <br/>
            Thickness = 8.4 cm
            <br/>
            U = 0.38 W/(m²K)
        </>
    },
    {
        variant: 3,
        title: 'Variant 3',
        wallDetails: <>
            Sandwich panel (PUR-core between 2 steel cover sheets)
            <br/>
            Thickness = 13.8 cm
            <br/>
            U = 0.19 W/(m²K)
        </>,
        roofDetails: <>
            Sandwich panel (PUR-core between 2 steel cover sheets)
            <br/>
            Thickness = 13.8 cm
            <br/>
            U = 0.19 W/(m²K)
        </>
    },
    {
        variant: 4,
        title: 'Variant 4',
        wallDetails: <>
            Precast concrete wall system (PUR-core between 2 concert panels)
            <br/>
            Thickness = 20 cm
            <br/>
            U = 0.50 W/(m²K)
        </>,
        roofDetails: <>
            Sandwich panel (PUR-core between 2 steel cover sheets)
            <br/>
            Thickness = 8.4 cm
            <br/>
            U = 0.38 W/(m²K)
        </>
    },
    {
        variant: 5,
        title: 'Variant 5',
        wallDetails: <>
            Precast concrete wall system (PUR-core between 2 concert panels)
            <br/>
            Thickness = 25 cm
            <br/>
            U = 0.32 W/(m²K)
        </>,
        roofDetails: <>
            Sandwich panel (PUR-core between 2 steel cover sheets)
            <br/>
            Thickness = 13.8 cm
            <br/>
            U = 0.19 W/(m²K)
        </>
    }
];

class RoofVariantDialog extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            open: false
        };
    }

    render() {
        return (
            <>
                <Link onClick={this.handleDialogOpen} to='/'>
                    More details
                </Link>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleDialogClose}>
                    <Paper>
                        <List>
                            {variants.map(variant => (
                                <ListItem button key={variant.variant}
                                          onClick={this.handleDialogSelect(variant.variant)}
                                          selected={variant.variant === this.props.variant}>
                                    <ListItemText
                                        primary={variant.title}
                                        secondary={<span className={styles.flex + ' ' + styles.marginTop}>
                                            <span>
                                                <Typography variant='subtitle2'>Wall</Typography>
                                                {variant.wallDetails}
                                            </span>
                                            <span className={styles.grow}/>
                                            <span className={styles.alignRight}>
                                                <Typography variant='subtitle2'>Roof</Typography>
                                                {variant.roofDetails}
                                            </span>
                                        </span>}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Dialog>
            </>
        );
    }

    handleDialogOpen = () => {
        this.setState({
            open: true
        });
    };

    handleDialogClose = () => {
        this.setState({
            open: false
        });
    };


    handleDialogSelect = (variant) => () => {
        this.props.updateBuildingDetails({
            variant
        });
        this.handleDialogClose();
    };
}

const mapStateToProps = state => ({
    variant: state.buildingDetails.variant
});

const mapDispatchToProps = dispatch => ({
    updateBuildingDetails: (data) => dispatch(updateBuildingDetails(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(RoofVariantDialog);