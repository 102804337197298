import * as React from 'react';
import { withTheme } from '@material-ui/core/styles';

const createUrl = ({size, path, mapType, color}) => `https://maps.googleapis.com/maps/api/staticmap?size=${size}x${size}&maptype=${mapType}&key=AIzaSyDsXZuhEc3wv7a4LXej8IUAgVCHG4fVfnA&path=color:0x00000000|weight:1|fillcolor:${color}|${path}`;

const StaticMap = ({path, theme}) => {
    path = path.map(point => `${point.lat()},${point.lng()}`);
    path.push(path[0]);
    path = path.join('|');
    const color = '0x' + theme.palette.primary.main.replace('#', '');
    const url = createUrl({
        size: 128,
        mapType: 'satellite',
        path, color
    });
    return <img src={url} alt="Static Map"/>
};

export default withTheme()(StaticMap);
