import React from "react";
import styles from "./RoofSearchHelper.module.css";

class RoofMeasuringHelper extends React.PureComponent {
    constructor() {
        super();
    };

    render() {
        return <div>
            <p>Now we can measure the surface of your roof. Begin on a corner of your building and click once.</p>
            <p>Now the drawing tool has been activated and by clicking on another corner it will draw a line between both corners.</p>
            <p>Go around your building until you're back at your starting point.</p>
            <img className={styles.measuringImage} src='/images/roof-measuring-animation.gif'
                 alt="Gif of zoom-in loop."/>
        </div>
    };

}

export default RoofMeasuringHelper;