import * as React from 'react';
import axios from 'axios';
import { withRouter } from "react-router";
import Calculation from './calculation/Calculation.jsx';
import Loader from '../components/Loader.jsx';
import env from '../environment';

class Result extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            loaded: false,
            data: undefined
        }
    }

    componentDidMount() {
        const params = {
            customerId: this.props.match.params.customerId,
            quotationId: this.props.match.params.quotationId
        }
        axios.get(`${env.api_url}/get_quotation.php`, { params })
            .then(result => {
                const { variant, height, solarpanels, skylights, path, area, firstname, city } = result.data;
                const data = {
                    variant, path, area, firstname, city,
                    solarpanels: Boolean(solarpanels),
                    skylights: Boolean(skylights),
                    height: height === 2 ? 2.5 : height,
                };
                this.setState({
                    loaded: true,
                    data
                });
            });
    }

    render() {
        const { loaded, data } = this.state;
        if (!loaded) {
            return <Loader size={150} />;
        }
        return <Calculation input={data} />
    }

}

export default withRouter(Result);
