import React from "react";
import styles from "./RoofMeasuringSuccess.module.css"

class RoofMeasuringSuccess extends React.PureComponent {
    constructor() {
        super();
    }

    render() {
        return <div className={styles.container}>
            <p>If your roof is highlighted as the picture below then you've successfully measured the surface of your roof.</p>
            <img className={styles.successImage} src='./images/roof_measured-succesfully.png' alt="Image of a successful measured roof surface"/>
            <p>If the finish button is grayed out then the surface of your roof isn't measured, make sure you followed the steps closely.</p>
        </div>
    }
}

export default RoofMeasuringSuccess;