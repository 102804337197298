export default [
  {
    "location": "Londen",
    "variant": 1,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 10.43423598,
    "Tover_zC_x": "-.120957447",
    "Tover_zC_x2": 426499e-9,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Londen",
    "variant": 1,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Londen",
    "variant": 1,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 12.37717602,
    "Tover_zC_x": "-.189361702",
    "Tover_zC_x2": 715667e-9,
    "Tover_zC_upBound": 117.875776,
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Londen",
    "variant": 1,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Londen",
    "variant": 1,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 17.21760155,
    "Tover_zC_x": "-.031170213",
    "Tover_zC_x2": 667e-7,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 8.811411992,
    "Tover_mC_x": "-.040319149",
    "Tover_mC_x2": 142166e-9,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Londen",
    "variant": 1,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Londen",
    "variant": 1,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 18.39168279,
    "Tover_zC_x": "-.090106383",
    "Tover_zC_x2": 320116e-9,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 7.348162476,
    "Tover_mC_x": "-.11787234",
    "Tover_mC_x2": 50677e-8,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Londen",
    "variant": 1,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Londen",
    "variant": 2,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 690.6073501,
    "Tover_zC_x": 0.471489362,
    "Tover_zC_x2": "-.002027079",
    "Tover_zC_upBound": -478.8627701,
    "Tover_mC_I": 561.6363636,
    "Tover_mC_x": 0.23,
    "Tover_mC_x2": -818182e-9,
    "Tover_mC_upBound": -699.801695
  },
  {
    "location": "Londen",
    "variant": 2,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 150.1789168,
    "Tover_zC_x": -2.265851064,
    "Tover_zC_x2": 0.008788201,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 71.55319149,
    "Tover_mC_x": -1.28106383,
    "Tover_mC_x2": 0.005382979,
    "Tover_mC_upBound": 89.55353985
  },
  {
    "location": "Londen",
    "variant": 2,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 708.115087,
    "Tover_zC_x": 0.195425532,
    "Tover_zC_x2": -871373e-9,
    "Tover_zC_upBound": -796.2781576,
    "Tover_mC_I": 507.8974855,
    "Tover_mC_x": 0.396595745,
    "Tover_mC_x2": "-.001938104",
    "Tover_mC_upBound": -419.7261205
  },
  {
    "location": "Londen",
    "variant": 2,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 163.106383,
    "Tover_zC_x": -2.49212766,
    "Tover_zC_x2": 0.009765957,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 44.07446809,
    "Tover_mC_x": "-.806489362",
    "Tover_mC_x2": 0.00343617,
    "Tover_mC_upBound": 86.61066709
  },
  {
    "location": "Londen",
    "variant": 2,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 864.8820116,
    "Tover_zC_x": 0.338723404,
    "Tover_zC_x2": "-.001249516",
    "Tover_zC_upBound": -707.3964897,
    "Tover_mC_I": 754.0145068,
    "Tover_mC_x": "-.070744681",
    "Tover_mC_x2": 604449e-9,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Londen",
    "variant": 2,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 303.6876209,
    "Tover_zC_x": -4.613297872,
    "Tover_zC_x2": 0.01865087,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 91.23404255,
    "Tover_mC_x": -1.334680851,
    "Tover_mC_x2": 0.005085106,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Londen",
    "variant": 2,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 880.9719536,
    "Tover_zC_x": 0.130106383,
    "Tover_zC_x2": -501934e-9,
    "Tover_zC_upBound": -1201.541714,
    "Tover_mC_I": 681.5647969,
    "Tover_mC_x": 0.502340426,
    "Tover_mC_x2": "-.002133462",
    "Tover_mC_upBound": -459.613514
  },
  {
    "location": "Londen",
    "variant": 2,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 299.6015474,
    "Tover_zC_x": -4.440212766,
    "Tover_zC_x2": 0.017731141,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 84.56673114,
    "Tover_mC_x": -1.340425532,
    "Tover_mC_x2": 0.005280464,
    "Tover_mC_upBound": 117.2045822
  },
  {
    "location": "Londen",
    "variant": 3,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 810.1576402,
    "Tover_zC_x": 1.144574468,
    "Tover_zC_x2": "-.00476499",
    "Tover_zC_upBound": -309.3710703,
    "Tover_mC_I": 742.8104449,
    "Tover_mC_x": 0.88106383,
    "Tover_mC_x2": "-.003564797",
    "Tover_mC_upBound": -349.3332879
  },
  {
    "location": "Londen",
    "variant": 3,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 100.8452611,
    "Tover_zC_x": -1.848723404,
    "Tover_zC_x2": 0.00788588,
    "Tover_zC_upBound": 86.36573884,
    "Tover_mC_I": 34.91489362,
    "Tover_mC_x": "-.648297872",
    "Tover_mC_x2": 0.002787234,
    "Tover_mC_upBound": 84.69961226
  },
  {
    "location": "Londen",
    "variant": 3,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 825.5793037,
    "Tover_zC_x": 0.871595745,
    "Tover_zC_x2": "-.003529014",
    "Tover_zC_upBound": -375.6996572,
    "Tover_mC_I": 702.4187621,
    "Tover_mC_x": 0.971170213,
    "Tover_mC_x2": "-.003884913",
    "Tover_mC_upBound": -318.2116582
  },
  {
    "location": "Londen",
    "variant": 3,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 245.6731141,
    "Tover_zC_x": -4.172553191,
    "Tover_zC_x2": 0.017046422,
    "Tover_zC_upBound": 98.58027818,
    "Tover_mC_I": 146.2804642,
    "Tover_mC_x": -2.62106383,
    "Tover_mC_x2": 0.011019342,
    "Tover_mC_upBound": 89.44291487
  },
  {
    "location": "Londen",
    "variant": 3,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1025.608317,
    "Tover_zC_x": 0.260106383,
    "Tover_zC_x2": "-.001320116",
    "Tover_zC_upBound": -788.3956823,
    "Tover_mC_I": 964,
    "Tover_mC_x": 0.27,
    "Tover_mC_x2": "-.001",
    "Tover_mC_upBound": -856.0726512
  },
  {
    "location": "Londen",
    "variant": 3,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 165.3810445,
    "Tover_zC_x": -2.624893617,
    "Tover_zC_x2": 0.01054352,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 99.16247582,
    "Tover_mC_x": -1.672340426,
    "Tover_mC_x2": 0.006769826,
    "Tover_mC_upBound": 98.85532203
  },
  {
    "location": "Londen",
    "variant": 3,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1016.318182,
    "Tover_zC_x": 0.505,
    "Tover_zC_x2": "-.002409091",
    "Tover_zC_upBound": -553.1045838,
    "Tover_mC_I": 922.4777563,
    "Tover_mC_x": 0.616808511,
    "Tover_mC_x2": "-.002760155",
    "Tover_mC_upBound": -477.0753335
  },
  {
    "location": "Londen",
    "variant": 3,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 321.6334623,
    "Tover_zC_x": -5.115851064,
    "Tover_zC_x2": 0.021060928,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 198.8027079,
    "Tover_mC_x": -3.33787234,
    "Tover_mC_x2": 0.013779497,
    "Tover_mC_upBound": 105.5615145
  },
  {
    "location": "Londen",
    "variant": 4,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 221.4448743,
    "Tover_zC_x": 3.043829787,
    "Tover_zC_x2": "-.008796905",
    "Tover_zC_upBound": -61.73674025,
    "Tover_mC_I": 123.7843327,
    "Tover_mC_x": 2.738404255,
    "Tover_mC_x2": "-.007652805",
    "Tover_mC_upBound": -40.5971852
  },
  {
    "location": "Londen",
    "variant": 4,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 19.04448743,
    "Tover_zC_x": "-.353617021",
    "Tover_zC_x2": 0.001520309,
    "Tover_zC_upBound": 84.69961226,
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Londen",
    "variant": 4,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 240.9381044,
    "Tover_zC_x": 2.668510638,
    "Tover_zC_x2": "-.007245648",
    "Tover_zC_upBound": -75.01145984,
    "Tover_mC_I": 118.1711799,
    "Tover_mC_x": 2.085212766,
    "Tover_mC_x2": "-.004867505",
    "Tover_mC_upBound": -50.67635214
  },
  {
    "location": "Londen",
    "variant": 4,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 19.04448743,
    "Tover_zC_x": "-.353617021",
    "Tover_zC_x2": 0.001520309,
    "Tover_zC_upBound": 84.69961226,
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Londen",
    "variant": 4,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 363.8239845,
    "Tover_zC_x": 4.621702128,
    "Tover_zC_x2": "-.013667311",
    "Tover_zC_upBound": -65.88431809,
    "Tover_mC_I": 250.8839458,
    "Tover_mC_x": 4.255957447,
    "Tover_mC_x2": "-.01183559",
    "Tover_mC_upBound": -51.55683185
  },
  {
    "location": "Londen",
    "variant": 4,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 119.8452611,
    "Tover_zC_x": -1.848723404,
    "Tover_zC_x2": 0.00788588,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 31.74081238,
    "Tover_mC_x": "-.589361702",
    "Tover_mC_x2": 0.002533849,
    "Tover_mC_upBound": 84.69961226
  },
  {
    "location": "Londen",
    "variant": 4,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 369.1295938,
    "Tover_zC_x": 4.534680851,
    "Tover_zC_x2": "-.013266925",
    "Tover_zC_upBound": -67.90928001,
    "Tover_mC_I": 173.8675048,
    "Tover_mC_x": 4.819468085,
    "Tover_mC_x2": "-.014853965",
    "Tover_mC_upBound": -32.76693852
  },
  {
    "location": "Londen",
    "variant": 4,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 129.0048356,
    "Tover_zC_x": -2.006914894,
    "Tover_zC_x2": 0.008534816,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 19.04448743,
    "Tover_mC_x": "-.353617021",
    "Tover_mC_x2": 0.001520309,
    "Tover_mC_upBound": 84.69961226
  },
  {
    "location": "Londen",
    "variant": 5,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 366.393617,
    "Tover_zC_x": 3.18712766,
    "Tover_zC_x2": "-.008265957",
    "Tover_zC_upBound": -92.68201362,
    "Tover_mC_I": 289.7804642,
    "Tover_mC_x": 3.38393617,
    "Tover_mC_x2": "-.010480658",
    "Tover_mC_upBound": -70.31922675
  },
  {
    "location": "Londen",
    "variant": 5,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 38.08897485,
    "Tover_zC_x": "-.707234043",
    "Tover_zC_x2": 0.003040619,
    "Tover_zC_upBound": 84.69961226,
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Londen",
    "variant": 5,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 390.3336557,
    "Tover_zC_x": 2.67287234,
    "Tover_zC_x2": "-.006097679",
    "Tover_zC_upBound": -115.5667197,
    "Tover_mC_I": 276.5764023,
    "Tover_mC_x": 3.015744681,
    "Tover_mC_x2": "-.008649903",
    "Tover_mC_upBound": -75.4030483
  },
  {
    "location": "Londen",
    "variant": 5,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 44.43713733,
    "Tover_zC_x": "-.825106383",
    "Tover_zC_x2": 0.003547389,
    "Tover_zC_upBound": 84.69961226,
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Londen",
    "variant": 5,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 551.6334623,
    "Tover_zC_x": 4.604148936,
    "Tover_zC_x2": "-.014939072",
    "Tover_zC_upBound": -92.21851742,
    "Tover_mC_I": 445.2988395,
    "Tover_mC_x": 4.962659574,
    "Tover_mC_x2": "-.015548356",
    "Tover_mC_upBound": -73.02315776
  },
  {
    "location": "Londen",
    "variant": 5,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 210.6025145,
    "Tover_zC_x": -3.641595745,
    "Tover_zC_x2": 0.015438104,
    "Tover_zC_upBound": 101.5547694,
    "Tover_mC_I": 115.6276596,
    "Tover_mC_x": -2.087553191,
    "Tover_mC_x2": 0.008819149,
    "Tover_mC_upBound": 88.41083985
  },
  {
    "location": "Londen",
    "variant": 5,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 539.287234,
    "Tover_zC_x": 4.839255319,
    "Tover_zC_x2": "-.016031915",
    "Tover_zC_upBound": -86.596772,
    "Tover_mC_I": 398.7205029,
    "Tover_mC_x": 5.299680851,
    "Tover_mC_x2": "-.017312379",
    "Tover_mC_upBound": -62.48177359
  },
  {
    "location": "Londen",
    "variant": 5,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 216.2253385,
    "Tover_zC_x": -3.722234043,
    "Tover_zC_x2": 0.015722437,
    "Tover_zC_upBound": 102.2617807,
    "Tover_mC_I": 79.35203095,
    "Tover_mC_x": -1.473404255,
    "Tover_mC_x2": 0.006334623,
    "Tover_mC_upBound": 84.69961226
  },
  {
    "location": "Nancy",
    "variant": 1,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 34.3762089,
    "Tover_zC_x": "-.077978723",
    "Tover_zC_x2": 87e-7,
    "Tover_zC_upBound": 464.9733602,
    "Tover_mC_I": 19.98549323,
    "Tover_mC_x": "-.099255319",
    "Tover_mC_x2": 395551e-9,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 1,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Nancy",
    "variant": 1,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 42.11605416,
    "Tover_zC_x": "-.285957447",
    "Tover_zC_x2": 83559e-8,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 18.69535783,
    "Tover_mC_x": "-.124361702",
    "Tover_mC_x2": 306576e-9,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 1,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Nancy",
    "variant": 1,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 47.20309478,
    "Tover_zC_x": "-.130425532",
    "Tover_zC_x2": 462282e-9,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 36.84042553,
    "Tover_mC_x": "-.111808511",
    "Tover_mC_x2": 351064e-9,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 1,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Nancy",
    "variant": 1,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 39.62282398,
    "Tover_zC_x": "-.080638298",
    "Tover_zC_x2": 284333e-9,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 31.1450677,
    "Tover_mC_x": "-.257446809",
    "Tover_mC_x2": 0.001044487,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 1,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Nancy",
    "variant": 2,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1038.302708,
    "Tover_zC_x": 0.61712766,
    "Tover_zC_x2": "-.002720503",
    "Tover_zC_upBound": -514.6889132,
    "Tover_mC_I": 898.0415861,
    "Tover_mC_x": 0.350531915,
    "Tover_mC_x2": "-.00160058",
    "Tover_mC_upBound": -647.5077948
  },
  {
    "location": "Nancy",
    "variant": 2,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 381.0696325,
    "Tover_zC_x": -5.829574468,
    "Tover_zC_x2": 0.023901354,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 146.8742747,
    "Tover_mC_x": -2.290212766,
    "Tover_mC_x2": 0.009094778,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 2,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1066.782398,
    "Tover_zC_x": 0.201170213,
    "Tover_zC_x2": "-.001066731",
    "Tover_zC_upBound": -910.166813,
    "Tover_mC_I": 856.2166344,
    "Tover_mC_x": 0.250212766,
    "Tover_mC_x2": "-.001640232",
    "Tover_mC_upBound": -650.2435131
  },
  {
    "location": "Nancy",
    "variant": 2,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 396.8520309,
    "Tover_zC_x": -6.068404255,
    "Tover_zC_x2": 0.024834623,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 106.7408124,
    "Tover_mC_x": -1.669361702,
    "Tover_mC_x2": 0.006533849,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 2,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1188.783366,
    "Tover_zC_x": 0.089787234,
    "Tover_zC_x2": -359768e-9,
    "Tover_zC_upBound": -1697.268218,
    "Tover_mC_I": 1052.318182,
    "Tover_mC_x": 0.335,
    "Tover_mC_x2": "-.001409091",
    "Tover_mC_upBound": -753.4459234
  },
  {
    "location": "Nancy",
    "variant": 2,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 553.8220503,
    "Tover_zC_x": -7.435531915,
    "Tover_zC_x2": 0.028918762,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 227.7311412,
    "Tover_mC_x": -3.335531915,
    "Tover_mC_x2": 0.013464217,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 2,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1189.695358,
    "Tover_zC_x": 0.145638298,
    "Tover_zC_x2": -693424e-9,
    "Tover_zC_upBound": -1209.029938,
    "Tover_mC_I": 1015.55029,
    "Tover_mC_x": 0.133085106,
    "Tover_mC_x2": -737911e-9,
    "Tover_mC_upBound": -1086.420792
  },
  {
    "location": "Nancy",
    "variant": 2,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 570.5038685,
    "Tover_zC_x": -7.600531915,
    "Tover_zC_x2": 0.029327853,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 172.8007737,
    "Tover_mC_x": -2.575106383,
    "Tover_mC_x2": 0.010365571,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 3,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1241.56383,
    "Tover_zC_x": 0.443723404,
    "Tover_zC_x2": "-.001840426",
    "Tover_zC_upBound": -709.5948485,
    "Tover_mC_I": 1150.810445,
    "Tover_mC_x": 0.61106383,
    "Tover_mC_x2": "-.002564797",
    "Tover_mC_upBound": -561.2316154
  },
  {
    "location": "Nancy",
    "variant": 3,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 187.9042553,
    "Tover_zC_x": -3.013085106,
    "Tover_zC_x2": 0.012010638,
    "Tover_zC_upBound": 116.0067624,
    "Tover_mC_I": 111.1334623,
    "Tover_mC_x": -1.870851064,
    "Tover_mC_x2": 0.007560928,
    "Tover_mC_upBound": 99.06393328
  },
  {
    "location": "Nancy",
    "variant": 3,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1244.824952,
    "Tover_zC_x": 0.440319149,
    "Tover_zC_x2": "-.001960348",
    "Tover_zC_upBound": -692.4387464,
    "Tover_mC_I": 1138.925532,
    "Tover_mC_x": 0.026489362,
    "Tover_mC_x2": 56383e-8,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 3,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 426.6499033,
    "Tover_zC_x": -6.689361702,
    "Tover_zC_x2": 0.027079304,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 283.72147,
    "Tover_mC_x": -4.731702128,
    "Tover_mC_x2": 0.019394584,
    "Tover_mC_upBound": 106.1272574
  },
  {
    "location": "Nancy",
    "variant": 3,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1365.044487,
    "Tover_zC_x": 0.186382979,
    "Tover_zC_x2": -479691e-9,
    "Tover_zC_upBound": -1503.789375,
    "Tover_mC_I": 1311.610251,
    "Tover_mC_x": "-.132659574",
    "Tover_mC_x2": 0.00109381,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 3,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 323.7930368,
    "Tover_zC_x": -5.004042553,
    "Tover_zC_x2": 0.020709865,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 188.2079304,
    "Tover_mC_x": -2.947340426,
    "Tover_mC_x2": 0.011997099,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 3,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1384.115087,
    "Tover_zC_x": "-.144574468",
    "Tover_zC_x2": 0.001128627,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 1251.882979,
    "Tover_mC_x": 0.567340426,
    "Tover_mC_x2": "-.002542553",
    "Tover_mC_upBound": -598.9378484
  },
  {
    "location": "Nancy",
    "variant": 3,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 680.8713733,
    "Tover_zC_x": -10.40606383,
    "Tover_zC_x2": 0.041973888,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 376.9980658,
    "Tover_mC_x": -5.997234043,
    "Tover_mC_x2": 0.024586074,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 4,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 511.6943907,
    "Tover_zC_x": 3.277021277,
    "Tover_zC_x2": "-.007400387",
    "Tover_zC_upBound": -122.3442176,
    "Tover_mC_I": 302.4758221,
    "Tover_mC_x": 4.369574468,
    "Tover_mC_x2": "-.013174081",
    "Tover_mC_upBound": -58.79936607
  },
  {
    "location": "Nancy",
    "variant": 4,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 98.55415861,
    "Tover_zC_x": -1.662446809,
    "Tover_zC_x2": 0.007089942,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 15.87040619,
    "Tover_mC_x": "-.294680851",
    "Tover_mC_x2": 0.001266925,
    "Tover_mC_upBound": 84.69961226
  },
  {
    "location": "Nancy",
    "variant": 4,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 529.8539652,
    "Tover_zC_x": 3.018829787,
    "Tover_zC_x2": "-.006751451",
    "Tover_zC_upBound": -134.848525,
    "Tover_mC_I": 252.6953578,
    "Tover_mC_x": 4.285638298,
    "Tover_mC_x2": "-.012693424",
    "Tover_mC_upBound": -51.19921736
  },
  {
    "location": "Nancy",
    "variant": 4,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 105.1334623,
    "Tover_zC_x": -1.770851064,
    "Tover_zC_x2": 0.007560928,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 12.69632495,
    "Tover_mC_x": "-.235744681",
    "Tover_mC_x2": 0.00101354,
    "Tover_mC_upBound": 84.69961226
  },
  {
    "location": "Nancy",
    "variant": 4,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 686.6489362,
    "Tover_zC_x": 5.132021277,
    "Tover_zC_x2": "-.01662766",
    "Tover_zC_upBound": -100.8463797,
    "Tover_mC_I": 562.4632495,
    "Tover_mC_x": 4.487553191,
    "Tover_mC_x2": "-.013364603",
    "Tover_mC_upBound": -97.20090944
  },
  {
    "location": "Nancy",
    "variant": 4,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 223.7601547,
    "Tover_zC_x": -3.037021277,
    "Tover_zC_x2": 0.012673114,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 90.45261122,
    "Tover_mC_x": -1.377234043,
    "Tover_mC_x2": 0.005858801,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 4,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 699.4903288,
    "Tover_zC_x": 4.908829787,
    "Tover_zC_x2": "-.015569632",
    "Tover_zC_upBound": -106.5128009,
    "Tover_mC_I": 469.9206963,
    "Tover_mC_x": 5.333404255,
    "Tover_mC_x2": "-.017970986",
    "Tover_mC_upBound": -71.08333582
  },
  {
    "location": "Nancy",
    "variant": 4,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 249.3839458,
    "Tover_zC_x": -3.499042553,
    "Tover_zC_x2": 0.01466441,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 53.75531915,
    "Tover_mC_x": "-.760106383",
    "Tover_mC_x2": 0.003138298,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 5,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 902.6373308,
    "Tover_zC_x": 1.728617021,
    "Tover_zC_x2": "-.003111219",
    "Tover_zC_upBound": -328.2476189,
    "Tover_mC_I": 739.8984526,
    "Tover_mC_x": 2.945212766,
    "Tover_mC_x2": "-.009231141",
    "Tover_mC_upBound": -165.4370806
  },
  {
    "location": "Nancy",
    "variant": 5,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 101.3085106,
    "Tover_zC_x": -1.771170213,
    "Tover_zC_x2": 0.007521277,
    "Tover_zC_upBound": 97.89311829,
    "Tover_mC_I": 30.65280464,
    "Tover_mC_x": "-.533510638",
    "Tover_mC_x2": 0.002200193,
    "Tover_mC_upBound": 93.53443413
  },
  {
    "location": "Nancy",
    "variant": 5,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 907.7959381,
    "Tover_zC_x": 1.681808511,
    "Tover_zC_x2": "-.003169246",
    "Tover_zC_upBound": -332.0286773,
    "Tover_mC_I": 778.1015474,
    "Tover_mC_x": 0.914787234,
    "Tover_mC_x2": 0.001231141,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 5,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 114.0048356,
    "Tover_zC_x": -2.006914894,
    "Tover_zC_x2": 0.008534816,
    "Tover_zC_upBound": 95.9942227,
    "Tover_mC_I": 21.85589942,
    "Tover_mC_x": "-.39393617",
    "Tover_mC_x2": 0.001662476,
    "Tover_mC_upBound": 88.63548765
  },
  {
    "location": "Nancy",
    "variant": 5,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1023.085106,
    "Tover_zC_x": 3.038297872,
    "Tover_zC_x2": "-.009787234",
    "Tover_zC_upBound": -203.4259514,
    "Tover_mC_I": 936.4226306,
    "Tover_mC_x": 2.845638298,
    "Tover_mC_x2": "-.00705706",
    "Tover_mC_upBound": -214.7274359
  },
  {
    "location": "Nancy",
    "variant": 5,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 344.8655706,
    "Tover_zC_x": -5.387765957,
    "Tover_zC_x2": 0.022732108,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 236.1102515,
    "Tover_mC_x": -3.917659574,
    "Tover_mC_x2": 0.01659381,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Nancy",
    "variant": 5,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1044.505803,
    "Tover_zC_x": 2.536702128,
    "Tover_zC_x2": "-.007258221",
    "Tover_zC_upBound": -242.9169673,
    "Tover_mC_I": 855.8413926,
    "Tover_mC_x": 3.716808511,
    "Tover_mC_x2": "-.010941973",
    "Tover_mC_upBound": -157.3624323
  },
  {
    "location": "Nancy",
    "variant": 5,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 363.5909091,
    "Tover_zC_x": -5.695,
    "Tover_zC_x2": 0.023954545,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 201.5009671,
    "Tover_mC_x": -3.356382979,
    "Tover_mC_x2": 0.014206963,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Praag",
    "variant": 1,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 3,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 1,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Praag",
    "variant": 1,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Praag",
    "variant": 1,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2.043520309,
    "Tover_zC_x": 0.027765957,
    "Tover_zC_x2": -186654e-9,
    "Tover_zC_upBound": -53.99741693,
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Praag",
    "variant": 1,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Praag",
    "variant": 1,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 5,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 4,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Praag",
    "variant": 1,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Praag",
    "variant": 1,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 7.174081238,
    "Tover_zC_x": "-.05893617",
    "Tover_zC_x2": 253385e-9,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 3,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Praag",
    "variant": 1,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Praag",
    "variant": 2,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 706.6218569,
    "Tover_zC_x": 0.740744681,
    "Tover_zC_x2": "-.003422631",
    "Tover_zC_upBound": -358.869429,
    "Tover_mC_I": 612.0280464,
    "Tover_mC_x": 0.309893617,
    "Tover_mC_x2": "-.001498066",
    "Tover_mC_upBound": -544.0589361
  },
  {
    "location": "Praag",
    "variant": 2,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 152.106383,
    "Tover_zC_x": -2.56212766,
    "Tover_zC_x2": 0.010765957,
    "Tover_zC_upBound": 113.4549348,
    "Tover_mC_I": 44.17504836,
    "Tover_mC_x": "-.710319149",
    "Tover_mC_x2": 0.002960348,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Praag",
    "variant": 2,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 725.6798839,
    "Tover_zC_x": 0.427765957,
    "Tover_zC_x2": "-.002004836",
    "Tover_zC_upBound": -504.3370427,
    "Tover_mC_I": 567.245648,
    "Tover_mC_x": 0.108723404,
    "Tover_mC_x2": -431335e-9,
    "Tover_mC_upBound": -1027.648844
  },
  {
    "location": "Praag",
    "variant": 2,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 164.4400387,
    "Tover_zC_x": -2.779255319,
    "Tover_zC_x2": 0.011668279,
    "Tover_zC_upBound": 109.5769396,
    "Tover_mC_I": 33.9139265,
    "Tover_mC_x": "-.536914894",
    "Tover_mC_x2": 0.002080271,
    "Tover_mC_upBound": 110.3125085
  },
  {
    "location": "Praag",
    "variant": 2,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 824.6373308,
    "Tover_zC_x": 0.388617021,
    "Tover_zC_x2": "-.001111219",
    "Tover_zC_upBound": -704.1603599,
    "Tover_mC_I": 739.7978723,
    "Tover_mC_x": 0.019042553,
    "Tover_mC_x2": 244681e-9,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Praag",
    "variant": 2,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 272.6731141,
    "Tover_zC_x": -3.832553191,
    "Tover_zC_x2": 0.015046422,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 109.1054159,
    "Tover_mC_x": -1.740744681,
    "Tover_mC_x2": 0.007058994,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Praag",
    "variant": 2,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 817.1305609,
    "Tover_zC_x": 0.723297872,
    "Tover_zC_x2": "-.002559961",
    "Tover_zC_upBound": -441.0980611,
    "Tover_mC_I": 684.9284333,
    "Tover_mC_x": 0.372340426,
    "Tover_mC_x2": "-.00131528",
    "Tover_mC_upBound": -593.8345576
  },
  {
    "location": "Praag",
    "variant": 2,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 282.2823985,
    "Tover_zC_x": -3.953829787,
    "Tover_zC_x2": 0.015433269,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 67.30560928,
    "Tover_mC_x": -1.067021277,
    "Tover_mC_x2": 0.004400387,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Praag",
    "variant": 3,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 871.9419729,
    "Tover_zC_x": 0.412978723,
    "Tover_zC_x2": "-.001417795",
    "Tover_zC_upBound": -651.9865291,
    "Tover_mC_I": 785.7108317,
    "Tover_mC_x": 0.843510638,
    "Tover_mC_x2": "-.003382012",
    "Tover_mC_upBound": -373.1620032
  },
  {
    "location": "Praag",
    "variant": 3,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 35.0589942,
    "Tover_zC_x": "-.524361702",
    "Tover_zC_x2": 0.002124758,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 27.4787234,
    "Tover_mC_x": "-.474574468",
    "Tover_mC_x2": 0.001946809,
    "Tover_mC_upBound": 94.65917255
  },
  {
    "location": "Praag",
    "variant": 3,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 885.6382979,
    "Tover_zC_x": 0.177234043,
    "Tover_zC_x2": -404255e-9,
    "Tover_zC_upBound": -1277.065761,
    "Tover_mC_I": 762.1460348,
    "Tover_mC_x": 0.781170213,
    "Tover_mC_x2": "-.003248549",
    "Tover_mC_upBound": -378.8327646
  },
  {
    "location": "Praag",
    "variant": 3,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 143.3965184,
    "Tover_zC_x": -2.367021277,
    "Tover_zC_x2": 0.009854932,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 64.11798839,
    "Tover_mC_x": -1.048723404,
    "Tover_mC_x2": 0.004249516,
    "Tover_mC_upBound": 111.6631631
  },
  {
    "location": "Praag",
    "variant": 3,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1013.970019,
    "Tover_zC_x": 0.28287234,
    "Tover_zC_x2": -915861e-9,
    "Tover_zC_upBound": -909.0414477,
    "Tover_mC_I": 941.6663443,
    "Tover_mC_x": 0.48712766,
    "Tover_mC_x2": "-.001902321",
    "Tover_mC_upBound": -587.0887793
  },
  {
    "location": "Praag",
    "variant": 3,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 147.3820116,
    "Tover_zC_x": -2.466276596,
    "Tover_zC_x2": 0.010250484,
    "Tover_zC_upBound": 110.59711,
    "Tover_mC_I": 51.92940039,
    "Tover_mC_x": "-.819042553",
    "Tover_mC_x2": 0.003391683,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Praag",
    "variant": 3,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1017.95648,
    "Tover_zC_x": 0.242234043,
    "Tover_zC_x2": -813346e-9,
    "Tover_zC_upBound": -979.6892396,
    "Tover_mC_I": 922.5512573,
    "Tover_mC_x": 0.391702128,
    "Tover_mC_x2": "-.001030948",
    "Tover_mC_upBound": -774.883944
  },
  {
    "location": "Praag",
    "variant": 3,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 385.1421663,
    "Tover_zC_x": -6.143297872,
    "Tover_zC_x2": 0.024923598,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 168.9197292,
    "Tover_mC_x": -2.755212766,
    "Tover_mC_x2": 0.01132205,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Praag",
    "variant": 4,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 81.30464217,
    "Tover_zC_x": 4.934361702,
    "Tover_zC_x2": "-.013306576",
    "Tover_zC_upBound": -15.80371128,
    "Tover_mC_I": 26.59187621,
    "Tover_mC_x": 4.113617021,
    "Tover_mC_x2": "-.011338491",
    "Tover_mC_upBound": -6.353103075
  },
  {
    "location": "Praag",
    "variant": 4,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1.912959381,
    "Tover_zC_x": "-.055531915",
    "Tover_zC_x2": 373308e-9,
    "Tover_zC_upBound": 54.18492244,
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Praag",
    "variant": 4,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 107.3781431,
    "Tover_zC_x": 4.339255319,
    "Tover_zC_x2": "-.010577369",
    "Tover_zC_upBound": -23.40989253,
    "Tover_mC_I": -9.176982592,
    "Tover_mC_x": 4.023085106,
    "Tover_mC_x2": "-.011374275",
    "Tover_mC_upBound": 2.295984889
  },
  {
    "location": "Praag",
    "variant": 4,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2.869439072,
    "Tover_zC_x": "-.083297872",
    "Tover_zC_x2": 559961e-9,
    "Tover_zC_upBound": 54.18492244,
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Praag",
    "variant": 4,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 427.6189555,
    "Tover_zC_x": 3.964893617,
    "Tover_zC_x2": "-.01254352",
    "Tover_zC_upBound": -84.99607426,
    "Tover_mC_I": 253.7205029,
    "Tover_mC_x": 5.199680851,
    "Tover_mC_x2": "-.017312379",
    "Tover_mC_upBound": -42.71926226
  },
  {
    "location": "Praag",
    "variant": 4,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 65.00193424,
    "Tover_zC_x": -1.032765957,
    "Tover_zC_x2": 0.004413926,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 10.30464217,
    "Tover_mC_x": "-.145638298",
    "Tover_mC_x2": 693424e-9,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Praag",
    "variant": 4,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 440.967118,
    "Tover_zC_x": 3.677021277,
    "Tover_zC_x2": "-.01103675",
    "Tover_zC_upBound": -93.61835128,
    "Tover_mC_I": 205.7524178,
    "Tover_mC_x": 5.134042553,
    "Tover_mC_x2": "-.016982592",
    "Tover_mC_upBound": -35.82962371
  },
  {
    "location": "Praag",
    "variant": 4,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 71.94390716,
    "Tover_zC_x": -1.159787234,
    "Tover_zC_x2": 0.004996132,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 14.60928433,
    "Tover_mC_x": "-.291276596",
    "Tover_mC_x2": 0.001386847,
    "Tover_mC_upBound": 82.79326044
  },
  {
    "location": "Praag",
    "variant": 5,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 231.5783366,
    "Tover_zC_x": 5.792978723,
    "Tover_zC_x2": "-.017235977",
    "Tover_zC_upBound": -36.09853743,
    "Tover_mC_I": 190.7524178,
    "Tover_mC_x": 4.614042553,
    "Tover_mC_x2": "-.010982592",
    "Tover_mC_upBound": -37.91922257
  },
  {
    "location": "Praag",
    "variant": 5,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Praag",
    "variant": 5,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 249.0580271,
    "Tover_zC_x": 5.377021277,
    "Tover_zC_x2": "-.015582205",
    "Tover_zC_upBound": -41.36131027,
    "Tover_mC_I": 165.8568665,
    "Tover_mC_x": 4.574680851,
    "Tover_mC_x2": "-.011630561",
    "Tover_mC_upBound": -33.41643142
  },
  {
    "location": "Praag",
    "variant": 5,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 0,
    "Tover_zC_x": 0,
    "Tover_zC_x2": 0,
    "Tover_zC_upBound": "Inf",
    "Tover_mC_I": 0,
    "Tover_mC_x": 0,
    "Tover_mC_x2": 0,
    "Tover_mC_upBound": "Inf"
  },
  {
    "location": "Praag",
    "variant": 5,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 610.4758221,
    "Tover_zC_x": 3.899574468,
    "Tover_zC_x2": "-.012174081",
    "Tover_zC_upBound": -115.1525835,
    "Tover_mC_I": 507.1866538,
    "Tover_mC_x": 4.643085106,
    "Tover_mC_x2": "-.015556093",
    "Tover_mC_upBound": -85.01806404
  },
  {
    "location": "Praag",
    "variant": 5,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 85.26402321,
    "Tover_zC_x": -1.417553191,
    "Tover_zC_x2": 0.006000967,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 36.69729207,
    "Tover_mC_x": "-.61712766",
    "Tover_mC_x2": 0.002720503,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Praag",
    "variant": 5,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 600.7659574,
    "Tover_zC_x": 4.094680851,
    "Tover_zC_x2": "-.013085106",
    "Tover_zC_upBound": -108.8533682,
    "Tover_mC_I": 490.098646,
    "Tover_mC_x": 4.35893617,
    "Tover_mC_x2": "-.013889749",
    "Tover_mC_upBound": -87.84562713
  },
  {
    "location": "Praag",
    "variant": 5,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 92.20599613,
    "Tover_zC_x": -1.544574468,
    "Tover_zC_x2": 0.006583172,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 23.04448743,
    "Tover_mC_x": "-.353617021",
    "Tover_mC_x2": 0.001520309,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 1,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1341.143133,
    "Tover_zC_x": -1.614680851,
    "Tover_zC_x2": 0.003630561,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 1156.984526,
    "Tover_mC_x": -2.17787234,
    "Tover_mC_x2": 0.006688588,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 1,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 577.7253385,
    "Tover_zC_x": -8.857234043,
    "Tover_zC_x2": 0.036222437,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 221.8897485,
    "Tover_mC_x": -3.552340426,
    "Tover_mC_x2": 0.01440619,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 1,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1400.030948,
    "Tover_zC_x": -3.154255319,
    "Tover_zC_x2": 0.009622824,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 1080.770793,
    "Tover_mC_x": -2.622234043,
    "Tover_mC_x2": 0.00844971,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 1,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 584.9729207,
    "Tover_zC_x": -9.071276596,
    "Tover_zC_x2": 0.037205029,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 182.4845261,
    "Tover_mC_x": -3.13287234,
    "Tover_mC_x2": 0.013188588,
    "Tover_mC_upBound": 102.3324469
  },
  {
    "location": "Madrid",
    "variant": 1,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1262.045455,
    "Tover_zC_x": -1.035,
    "Tover_zC_x2": 0.003227273,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 1102.552224,
    "Tover_mC_x": -1.269680851,
    "Tover_mC_x2": 0.004676015,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 1,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 605.9526112,
    "Tover_zC_x": -8.472234043,
    "Tover_zC_x2": 0.034358801,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 290.0338491,
    "Tover_mC_x": -4.138404255,
    "Tover_mC_x2": 0.016743714,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 1,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 1251.144101,
    "Tover_zC_x": -1.32606383,
    "Tover_zC_x2": 0.004337524,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 1014.898453,
    "Tover_mC_x": -1.164787234,
    "Tover_mC_x2": 0.003768859,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 1,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 596.9535783,
    "Tover_zC_x": -8.413617021,
    "Tover_zC_x2": 0.034065764,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 207.5,
    "Tover_mC_x": -2.905,
    "Tover_mC_x2": 0.0115,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 2,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2801.374275,
    "Tover_zC_x": "-.125212766",
    "Tover_zC_x2": -405222e-9,
    "Tover_zC_upBound": -2788.326153,
    "Tover_mC_I": 2553.852998,
    "Tover_mC_x": 0.380212766,
    "Tover_mC_x2": "-.002458414",
    "Tover_mC_upBound": -944.8262774
  },
  {
    "location": "Madrid",
    "variant": 2,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2511.245648,
    "Tover_zC_x": -26.0612766,
    "Tover_zC_x2": 0.100568665,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 1974.44971,
    "Tover_mC_x": -22.89308511,
    "Tover_mC_x2": 0.088737911,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 2,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2836.028046,
    "Tover_zC_x": "-.770106383",
    "Tover_zC_x2": 0.002501934,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2496.970986,
    "Tover_mC_x": "-.298510638",
    "Tover_mC_x2": 791103e-9,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 2,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2549.608317,
    "Tover_zC_x": -26.44989362,
    "Tover_zC_x2": 0.101679884,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 1847.475822,
    "Tover_mC_x": -22.40042553,
    "Tover_mC_x2": 0.087825919,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 2,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2611.521277,
    "Tover_zC_x": "-.435425532",
    "Tover_zC_x2": 0.001053191,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2447.507737,
    "Tover_mC_x": "-.20606383",
    "Tover_mC_x2": 155706e-9,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 2,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2401.751451,
    "Tover_zC_x": -21.55457447,
    "Tover_zC_x2": 0.082310445,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2033.813346,
    "Tover_mC_x": -22.28308511,
    "Tover_mC_x2": 0.087556093,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 2,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2645.40619,
    "Tover_zC_x": -1.070851064,
    "Tover_zC_x2": 0.003924565,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2384.755319,
    "Tover_mC_x": "-.150106383",
    "Tover_mC_x2": 138298e-9,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 2,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2423.911025,
    "Tover_zC_x": -21.71276596,
    "Tover_zC_x2": 0.082959381,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 1901.984526,
    "Tover_mC_x": -21.63787234,
    "Tover_mC_x2": 0.084688588,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 3,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 3193.59381,
    "Tover_zC_x": "-.749148936",
    "Tover_zC_x2": 0.002075435,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 3084.693424,
    "Tover_mC_x": "-.711595745",
    "Tover_mC_x2": 0.00189265,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 3,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2456.999033,
    "Tover_zC_x": -29.84861702,
    "Tover_zC_x2": 0.117293037,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2107.092843,
    "Tover_mC_x": -26.68276596,
    "Tover_mC_x2": 0.104868472,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 3,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 3208.320116,
    "Tover_zC_x": "-.997765957",
    "Tover_zC_x2": 0.003004836,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 3047.883946,
    "Tover_mC_x": "-.994042553",
    "Tover_mC_x2": 0.00316441,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 3,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2872.621857,
    "Tover_zC_x": -33.09925532,
    "Tover_zC_x2": 0.128577369,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2503.498066,
    "Tover_mC_x": -31.39223404,
    "Tover_mC_x2": 0.123086074,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 3,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2982.988395,
    "Tover_zC_x": -1.273404255,
    "Tover_zC_x2": 0.004516441,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2869.147002,
    "Tover_mC_x": "-.880212766",
    "Tover_mC_x2": 0.002458414,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 3,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2446.395551,
    "Tover_zC_x": -27.7556383,
    "Tover_zC_x2": 0.109147969,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2091.426499,
    "Tover_mC_x": -24.83989362,
    "Tover_mC_x2": 0.097770793,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 3,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2964.741779,
    "Tover_zC_x": "-.830744681",
    "Tover_zC_x2": 0.002240812,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2850.088008,
    "Tover_mC_x": -1.235851064,
    "Tover_mC_x2": 0.004333656,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 3,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2940.709865,
    "Tover_zC_x": -32.34510638,
    "Tover_zC_x2": 0.125911025,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2533.882979,
    "Tover_mC_x": -30.71265957,
    "Tover_mC_x2": 0.121457447,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 4,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2808.578337,
    "Tover_zC_x": "-.127021277",
    "Tover_zC_x2": "-.001235977",
    "Tover_zC_upBound": -1559.69385,
    "Tover_mC_I": 2660.155706,
    "Tover_mC_x": -1.672659574,
    "Tover_mC_x2": 0.005821083,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 4,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2630.766925,
    "Tover_zC_x": -29.67670213,
    "Tover_zC_x2": 0.115621857,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2215.891683,
    "Tover_mC_x": -28.90510638,
    "Tover_mC_x2": 0.114820116,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 4,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2836.636364,
    "Tover_zC_x": "-.71",
    "Tover_zC_x2": 0.001181818,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2559.345261,
    "Tover_mC_x": -1.233723404,
    "Tover_mC_x2": 0.00338588,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 4,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2673.43617,
    "Tover_zC_x": -30.2637234,
    "Tover_zC_x2": 0.117840426,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2067.428433,
    "Tover_mC_x": -28.00265957,
    "Tover_mC_x2": 0.11118472,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 4,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2809.736944,
    "Tover_zC_x": -1.383829787,
    "Tover_zC_x2": 0.001705996,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2649.984526,
    "Tover_mC_x": -2.92787234,
    "Tover_mC_x2": 0.011688588,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 4,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2495.354932,
    "Tover_zC_x": -25.95755319,
    "Tover_zC_x2": 0.103455513,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 1966.061896,
    "Tover_mC_x": -22.94851064,
    "Tover_mC_x2": 0.091245648,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 4,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2832.505803,
    "Tover_zC_x": -1.833297872,
    "Tover_zC_x2": 0.003741779,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2526.146035,
    "Tover_mC_x": -2.088829787,
    "Tover_mC_x2": 0.007751451,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 4,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2539.980658,
    "Tover_zC_x": -26.57234043,
    "Tover_zC_x2": 0.105860735,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 1882.512573,
    "Tover_mC_x": -23.36297872,
    "Tover_mC_x2": 0.093690522,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 5,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 3056.17795,
    "Tover_zC_x": 0.375531915,
    "Tover_zC_x2": -918762e-9,
    "Tover_zC_upBound": -1630.890382,
    "Tover_mC_I": 2869.410058,
    "Tover_mC_x": 2.343617021,
    "Tover_mC_x2": "-.011247582",
    "Tover_mC_upBound": -411.5373467
  },
  {
    "location": "Madrid",
    "variant": 5,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 3090.998066,
    "Tover_zC_x": -37.46723404,
    "Tover_zC_x2": 0.145586074,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2931.011605,
    "Tover_mC_x": -38.71659574,
    "Tover_mC_x2": 0.152483559,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 5,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 3066.469052,
    "Tover_zC_x": 0.189255319,
    "Tover_zC_x2": -122824e-9,
    "Tover_zC_upBound": -4285.250582,
    "Tover_mC_I": 2840.404255,
    "Tover_mC_x": 1.991914894,
    "Tover_mC_x2": "-.009489362",
    "Tover_mC_upBound": -452.1271503
  },
  {
    "location": "Madrid",
    "variant": 5,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 3113.723404,
    "Tover_zC_x": -37.77446809,
    "Tover_zC_x2": 0.146808511,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2850.028046,
    "Tover_mC_x": -38.40010638,
    "Tover_mC_x2": 0.151501934,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 5,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 3213.833656,
    "Tover_zC_x": -2.40212766,
    "Tover_zC_x2": 0.006402321,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 3067.939072,
    "Tover_mC_x": -1.64287234,
    "Tover_mC_x2": 0.003461315,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 5,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2841.586074,
    "Tover_zC_x": -32.25808511,
    "Tover_zC_x2": 0.126419729,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2581.226306,
    "Tover_mC_x": -31.25361702,
    "Tover_mC_x2": 0.1224294,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 5,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 3207.791103,
    "Tover_zC_x": -2.201276596,
    "Tover_zC_x2": 0.005295938,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2972.941006,
    "Tover_mC_x": "-.885638298",
    "Tover_mC_x2": 875242e-9,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Madrid",
    "variant": 5,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 2872.151838,
    "Tover_zC_x": -32.67712766,
    "Tover_zC_x2": 0.12799323,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 2539.396518,
    "Tover_mC_x": -31.71702128,
    "Tover_mC_x2": 0.124854932,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 1,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6640.391683,
    "Tover_zC_x": "-.660106383",
    "Tover_zC_x2": 0.001320116,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6418.824952,
    "Tover_mC_x": "-.129680851",
    "Tover_mC_x2": -960348e-9,
    "Tover_mC_upBound": -2653.713786
  },
  {
    "location": "Riyadh",
    "variant": 1,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6943.412959,
    "Tover_zC_x": -22.34053191,
    "Tover_zC_x2": 0.085873308,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6532.576402,
    "Tover_mC_x": -19.70425532,
    "Tover_mC_x2": 0.077350097,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 1,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6642.393617,
    "Tover_zC_x": "-.64287234",
    "Tover_zC_x2": 734043e-9,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6378.286267,
    "Tover_mC_x": "-.639361702",
    "Tover_mC_x2": 261122e-9,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 1,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6937.572534,
    "Tover_zC_x": -21.8887234,
    "Tover_zC_x2": 0.083522244,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6438.477756,
    "Tover_mC_x": -18.97319149,
    "Tover_mC_x2": 0.074239845,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 1,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6624.246615,
    "Tover_zC_x": "-.742659574",
    "Tover_zC_x2": 0.002275629,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6438.129594,
    "Tover_mC_x": "-.345319149",
    "Tover_mC_x2": 733075e-9,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 1,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 7060.146035,
    "Tover_zC_x": -23.39882979,
    "Tover_zC_x2": 0.090751451,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6697.459381,
    "Tover_mC_x": -21.73691489,
    "Tover_mC_x2": 0.084807544,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 1,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6624.841393,
    "Tover_zC_x": "-.693191489",
    "Tover_zC_x2": 0.002058027,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6391.812379,
    "Tover_mC_x": "-.961702128",
    "Tover_mC_x2": 0.00384913,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 1,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 7097.380077,
    "Tover_zC_x": -23.75351064,
    "Tover_zC_x2": 0.091836557,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6543.232108,
    "Tover_mC_x": -19.92191489,
    "Tover_mC_x2": 0.07667118,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 2,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6781.638298,
    "Tover_zC_x": 0.077234043,
    "Tover_zC_x2": -404255e-9,
    "Tover_zC_upBound": -4001.394095,
    "Tover_mC_I": 6631.955513,
    "Tover_mC_x": 0.523617021,
    "Tover_mC_x2": "-.002520309",
    "Tover_mC_upBound": -1521.603857
  },
  {
    "location": "Riyadh",
    "variant": 2,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6822.12766,
    "Tover_zC_x": -23.35255319,
    "Tover_zC_x2": 0.088319149,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6409.480658,
    "Tover_mC_x": -21.52734043,
    "Tover_mC_x2": 0.082360735,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 2,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6793.970986,
    "Tover_zC_x": "-.198510638",
    "Tover_zC_x2": 791103e-9,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6590.433269,
    "Tover_mC_x": "-.009574468",
    "Tover_mC_x2": -280464e-9,
    "Tover_mC_upBound": -4864.603885
  },
  {
    "location": "Riyadh",
    "variant": 2,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6830.533849,
    "Tover_zC_x": -23.17340426,
    "Tover_zC_x2": 0.087243714,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6304.239845,
    "Tover_mC_x": -20.97297872,
    "Tover_mC_x2": 0.080326886,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 2,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6595.524178,
    "Tover_zC_x": -1.409574468,
    "Tover_zC_x2": 0.005174081,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6482.478723,
    "Tover_mC_x": -1.284574468,
    "Tover_mC_x2": 0.004946809,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 2,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 7074.294971,
    "Tover_zC_x": -25.47180851,
    "Tover_zC_x2": 0.095623791,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6691.754352,
    "Tover_mC_x": -25.3287234,
    "Tover_mC_x2": 0.096431335,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 2,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6585.321083,
    "Tover_zC_x": -1.279148936,
    "Tover_zC_x2": 0.004711799,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6435.928433,
    "Tover_mC_x": -1.077659574,
    "Tover_mC_x2": 0.00368472,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 2,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 7079.154739,
    "Tover_zC_x": -25.1912766,
    "Tover_zC_x2": 0.09411412,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6568.911025,
    "Tover_mC_x": -24.81276596,
    "Tover_mC_x2": 0.092959381,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 3,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6973.579304,
    "Tover_zC_x": "-.578404255",
    "Tover_zC_x2": 0.001470986,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6868.187621,
    "Tover_mC_x": "-.218297872",
    "Tover_mC_x2": 15087e-8,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 3,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6922.294004,
    "Tover_zC_x": -27.43042553,
    "Tover_zC_x2": 0.105916828,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6616.426499,
    "Tover_mC_x": -24.35989362,
    "Tover_mC_x2": 0.093770793,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 3,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6979.435203,
    "Tover_zC_x": "-.702340426",
    "Tover_zC_x2": 0.002133462,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6860.681818,
    "Tover_mC_x": "-.705",
    "Tover_mC_x2": 0.002409091,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 3,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6865.763056,
    "Tover_zC_x": -27.41117021,
    "Tover_zC_x2": 0.106794004,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6490.358801,
    "Tover_mC_x": -23.44308511,
    "Tover_mC_x2": 0.088283366,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 3,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6784.101547,
    "Tover_zC_x": -1.295212766,
    "Tover_zC_x2": 0.004231141,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6714.144101,
    "Tover_mC_x": -1.22606383,
    "Tover_mC_x2": 0.004337524,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 3,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 7350.60058,
    "Tover_zC_x": -33.23882979,
    "Tover_zC_x2": 0.130024178,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6973.300774,
    "Tover_mC_x": -29.55010638,
    "Tover_mC_x2": 0.112865571,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 3,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6788.262089,
    "Tover_zC_x": -1.394787234,
    "Tover_zC_x2": 0.004587041,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6700.611219,
    "Tover_mC_x": -1.524042553,
    "Tover_mC_x2": 0.005800774,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 3,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 7358.472921,
    "Tover_zC_x": -34.2262766,
    "Tover_zC_x2": 0.133705029,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6868.411992,
    "Tover_mC_x": -30.09914894,
    "Tover_mC_x2": 0.116166344,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 4,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6625.450677,
    "Tover_zC_x": 1.845531915,
    "Tover_zC_x2": "-.005555126",
    "Tover_zC_upBound": -938.5458609,
    "Tover_mC_I": 6402.027079,
    "Tover_mC_x": 2.371276596,
    "Tover_mC_x2": "-.007205029",
    "Tover_mC_upBound": -792.3279097
  },
  {
    "location": "Riyadh",
    "variant": 4,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6726.844294,
    "Tover_zC_x": -23.82734043,
    "Tover_zC_x2": 0.094178917,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6311.88588,
    "Tover_mC_x": -19.71680851,
    "Tover_mC_x2": 0.074578337,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 4,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6628.421663,
    "Tover_zC_x": 1.987021277,
    "Tover_zC_x2": "-.006764023",
    "Tover_zC_upBound": -853.8814655,
    "Tover_mC_I": 6340.260155,
    "Tover_mC_x": 1.957978723,
    "Tover_mC_x2": "-.005826886",
    "Tover_mC_upBound": -888.553963
  },
  {
    "location": "Riyadh",
    "variant": 4,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6755.281431,
    "Tover_zC_x": -24.21244681,
    "Tover_zC_x2": 0.095726306,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6200.967118,
    "Tover_mC_x": -18.55297872,
    "Tover_mC_x2": 0.06796325,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 4,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6804.134429,
    "Tover_zC_x": -1.462234043,
    "Tover_zC_x2": 0.002267892,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6570.663443,
    "Tover_mC_x": 0.561276596,
    "Tover_mC_x2": "-.006023211",
    "Tover_mC_upBound": -998.9029533
  },
  {
    "location": "Riyadh",
    "variant": 4,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6873.724371,
    "Tover_zC_x": -23.93585106,
    "Tover_zC_x2": 0.092515474,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6514.204062,
    "Tover_mC_x": -23.13180851,
    "Tover_mC_x2": 0.088169246,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 4,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6796.568665,
    "Tover_zC_x": -1.313191489,
    "Tover_zC_x2": 0.001694391,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6536.970019,
    "Tover_mC_x": "-.14712766",
    "Tover_mC_x2": "-.001915861",
    "Tover_mC_upBound": -1885.963813
  },
  {
    "location": "Riyadh",
    "variant": 4,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6889.582205,
    "Tover_zC_x": -24.11255319,
    "Tover_zC_x2": 0.093591876,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6417.551257,
    "Tover_mC_x": -22.93829787,
    "Tover_mC_x2": 0.087969052,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 5,
    "height": 6,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6786.508704,
    "Tover_zC_x": 1.232553191,
    "Tover_zC_x2": "-.004137331",
    "Tover_zC_upBound": -1140.424003,
    "Tover_mC_I": 6682.146035,
    "Tover_mC_x": 0.771170213,
    "Tover_mC_x2": -248549e-9,
    "Tover_mC_upBound": -3860.796798
  },
  {
    "location": "Riyadh",
    "variant": 5,
    "height": 6,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6853.753385,
    "Tover_zC_x": -27.79734043,
    "Tover_zC_x2": 0.109724371,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6529.400387,
    "Tover_mC_x": -23.84255319,
    "Tover_mC_x2": 0.091682785,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 5,
    "height": 6,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6804.797872,
    "Tover_zC_x": 0.759042553,
    "Tover_zC_x2": "-.001755319",
    "Tover_zC_upBound": -1764.550461,
    "Tover_mC_I": 6648.088975,
    "Tover_mC_x": 0.602765957,
    "Tover_mC_x2": 406e-7,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 5,
    "height": 6,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6857.840426,
    "Tover_zC_x": -27.74180851,
    "Tover_zC_x2": 0.109351064,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6471.281431,
    "Tover_mC_x": -23.25244681,
    "Tover_mC_x2": 0.087726306,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 5,
    "height": 2.5,
    "solarpanels": true,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6955.944874,
    "Tover_zC_x": -1.801170213,
    "Tover_zC_x2": 0.004703095,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6862.724371,
    "Tover_mC_x": -1.745851064,
    "Tover_mC_x2": 0.005515474,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 5,
    "height": 2.5,
    "solarpanels": true,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6996.601547,
    "Tover_zC_x": -28.92021277,
    "Tover_zC_x2": 0.111731141,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6738.586074,
    "Tover_mC_x": -27.28808511,
    "Tover_mC_x2": 0.105419729,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 5,
    "height": 2.5,
    "solarpanels": false,
    "skylights": true,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 6951.409091,
    "Tover_zC_x": -1.665,
    "Tover_zC_x2": 0.004045455,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6814.534816,
    "Tover_mC_x": -1.644787234,
    "Tover_mC_x2": 0.005950677,
    "Tover_mC_upBound": "NA"
  },
  {
    "location": "Riyadh",
    "variant": 5,
    "height": 2.5,
    "solarpanels": false,
    "skylights": false,
    "temperature": 21,
    "airtightness": "High",
    "Tover_zC_I": 7006.746615,
    "Tover_zC_x": -29.07765957,
    "Tover_zC_x2": 0.112775629,
    "Tover_zC_upBound": "NA",
    "Tover_mC_I": 6667.959381,
    "Tover_mC_x": -26.59191489,
    "Tover_mC_x2": 0.101307544,
    "Tover_mC_upBound": "NA"
  }
]
