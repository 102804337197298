import React from "react";
import styles from "./BuildingZoomInHelper.module.css"

class BuildingZoomInHelper extends React.PureComponent {
    constructor(props) {
        super(props);

    }

    render() {
        return <div>
            <p>Now that you have found your location zoom in with by holding the CTRL key + scrolling the wheel of your mouse to zoom in on your building.</p>
            <p>The map will zoom in on your cursor so make sure your cursor is pointing onto the building.</p>
            <p>Once the building is the majority of your screen we can go to the next step measuring the surface of your roof</p>
            <img className={styles.zoomInImage} src='/images/zoom-in-animation.gif' alt="Gif of zoom-in loop."/>
        </div>
    }
}

export default BuildingZoomInHelper;