import styles from "./AppBarLogo.module.css";
import * as React from "react";

const AppBarLogo = ({gutter}) => <>
    {gutter && <div className={styles.gutter}/>}
    <img src='/images/2white.png' alt='2WHITE' className={styles.logo} />
</>;

export default AppBarLogo;

