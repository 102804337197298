import * as React from 'react';
import Typography from "@material-ui/core/Typography/Typography";
import { connect } from "react-redux";
import styles from './EmailSent.module.css';
import Button from "@material-ui/core/Button/Button";
import axios from 'axios';
import env from '../../environment';

class EmailSent extends React.PureComponent {

    componentDidMount() {
        const { buildingDetails, userDetails } = this.props;
        axios.post(`${env.api_url}/add_quotation.php`, {
            building: {
                variant: buildingDetails.variant,
                height: buildingDetails.height,
                solarpanels: buildingDetails.solarpanels,
                skylights: buildingDetails.skylights,
                path: buildingDetails.encodedPath,
                area: buildingDetails.area,
            },
            customer: {
                firstname: userDetails.firstName,
                lastname: userDetails.lastName,
                city: userDetails.city,
                email: userDetails.email,
            }
        });
    }

    render() {
        const { userDetails } = this.props;
        return (
            <div className={styles.container}>
                <Typography variant="h5" gutterBottom>
                    Thank you for your submission
                </Typography>
                <Typography variant="subtitle1">
                    We have emailed your quotation to <b>{userDetails.email}</b>. Check your mailbox for the outcome.
                </Typography>

                <div className={styles.startOverContainer + ' ' + styles.flex}>
                    <div className={styles.grow} />
                    <Button
                        onClick={refreshPage}
                        variant='contained'
                        color='primary'
                    >
                        Start over
                    </Button>
                </div>
            </div>
        );
    }

}

const refreshPage = () => {
    window.location.reload();
};

const mapStateToProps = state => ({
    buildingDetails: state.buildingDetails,
    userDetails: state.userDetails
});

export default connect(mapStateToProps)(EmailSent);
