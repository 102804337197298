export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_DETAILS_READY = "UPDATE_USER_DETAILS_READY";

export const updateUserDetails = data => dispatch => {
    dispatch({
        type: UPDATE_USER_DETAILS,
        payload: data
    });
};

export const updateUserDetailsReady = ready => dispatch => {
    dispatch({
        type: UPDATE_USER_DETAILS_READY,
        payload: ready
    });
};