import * as React from 'react';
import { Route, Redirect, Switch } from "react-router";
import Selector2WhitePage from "./pages/Main-page";
import Result from "./pages/Result-page";

const Routing = () => (
    <Switch>
        <Route exact path='/' component={Selector2WhitePage} />
        <Route exact path='/result/:customerId/:quotationId' component={Result} />
        <Route render={() => <Redirect to="/" />} />
    </Switch>
);

export default Routing;
