import * as React from 'react';
import MUILink from "@material-ui/core/Link/Link";
import {Link as RouterLink} from "react-router-dom";

const Link = ({children, ...props}) => (
    <MUILink component={RouterLink} {...props}>
        {children}
    </MUILink>
);

export default Link;