import * as React from 'react';
import styles from './Calculation.module.css';
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import data2white from './2white.js';
import { calculateOverheat, calculates00p, calculate2white } from "./calculation.js";
import { calculateClosestLocation } from "./locator";
import Avatar from "@material-ui/core/Avatar/Avatar";
import { Link } from 'react-router-dom';
import ShopifyBuy from "../shopify/shopify-buy";

class Calculation extends React.Component {

    render() {
        const { input } = this.props;
        const { area } = input;

        const filter = {
            location: calculateClosestLocation(input.path),
            height: input.height,
            variant: input.variant,
            solarpanels: input.solarpanels,
            skylights: input.skylights,
            temperature: 21
        };

        const data = data2white.find(d => {
            for (const key in filter) {
                if (d[key] === undefined || d[key] !== filter[key])
                    return false;
            }
            return true;
        });

        if (data === undefined) return this.renderError();

        const { withCoating ,withoutCoating, difference, percentage } = calculateOverheat(area, data);
        if (difference < 0) return this.renderNotWorth();

        const s00p = calculates00p(area);
        const white2 = calculate2white(area);

        return (
            <div className={styles.container}>
                <Typography variant="h5" gutterBottom>
                    Congratulations {input.firstname}, you perfectly completed the 2WHITE pre-calculation tool.
                </Typography>
                <Typography variant="subtitle1">
                    <br />
                    Your building in {input.city} will become {difference} hours less overheated by painting your roof with our 2WHITE coating.
                    <br />
                    The building will become {percentage}% more comfortable.
                    <br />
                    Because of the current black roof, the interior gets annually {withoutCoating} hours above 25 ℃.
                    <br />
                    After the application of 2WHITE your interior will only be {withCoating} hours above 25 ℃ .
                    <br /><br />
                    For your {area}m² roof you need {white2.liters} liter(s) 2WHITE and {s00p.liters} liter(s) s00p RWC cleaner.
                    <br /><br />
                    <span>
                    <a href="https://www.liquisol.com/nl/collection/2white" rel="noreferrer noopener" target="_blank">This video tutorial</a> explains how to apply the coating on your roof.
                    </span>
                    <br /><br />
                    Let’s get Wiser with Energy.
                </Typography>
                <div className={styles.avatarContainer}>
                    <Avatar alt="Tom Huysmans" src="/images/tom-huysmans.jpg" className={styles.avatar} />
                    <Typography variant='subtitle2'>
                        Tom Huysmans
                    </Typography>
                    <Typography variant='caption'>
                        CEO of Liquisol
                    </Typography>
                </div>
                {/* <div className={styles.shopify}>
                    <ShopifyBuy/>
                </div> */}

                <div className={styles.startOverContainer + ' ' + styles.flex}>
                    <div className={styles.grow} />
                    <Button
                        component={Link}
                        to='/'
                        variant='contained'
                        color='primary'
                    >
                        Start over
                    </Button>
                </div>
            </div>
        );
    }

    renderNotWorth = () => (
        <div className={styles.container}>
            <Typography variant="h5" gutterBottom>
                Thank you for your interest in our solar reflective coating 2WHITE.
            </Typography>
            <Typography variant="subtitle1">
                Unfortunately, 2WHITE does not seem to have a positive impact on your building.
                <br />
                We're very sorry about this.
            </Typography>

            <div className={styles.startOverContainer + ' ' + styles.flex}>
                <div className={styles.grow} />
                <Button
                    component={Link}
                    to='/'
                    variant='contained'
                    color='primary'
                >
                    Start over
                </Button>
            </div>
        </div>
    );

    renderError = () => (
        <div className={styles.container}>
            <Typography variant="h5" gutterBottom>
                Something unfortunately went wrong.
            </Typography>
            <Typography variant="subtitle1">
                We're sorry for your inconvenience.
            </Typography>

            <div className={styles.startOverContainer + ' ' + styles.flex}>
                <div className={styles.grow} />
                <Button
                    component={Link}
                    to='/'
                    variant='contained'
                    color='primary'
                >
                    Start over
                </Button>
            </div>
        </div>
    );
}

export default Calculation;
