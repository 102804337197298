import { createMuiTheme } from '@material-ui/core/styles';
import amber from "@material-ui/core/colors/amber";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import * as React from "react";
import connect from "react-redux/es/connect/connect";

const t = {
    palette: {
        primary: {
            main: '#b30e1b',
        },
        secondary: amber
    },
    typography: {
        useNextVariants: true,
    },
};
const lightTheme = createMuiTheme(t);
t.palette.type = 'dark';
t.palette.background = {
    paper: '#0f0f0f',
    default: '#000'
};
const darkTheme = createMuiTheme(t);

const Theme = ({isDarkTheme, children}) => (
    <MuiThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        {children}
    </MuiThemeProvider>
);

const mapStateToProps = state => ({
    isDarkTheme: state.theme.darkTheme
});

export default connect(mapStateToProps, null)(Theme);
