import * as React from 'react';
import Button from "@material-ui/core/Button/Button";
import Fab from '@material-ui/core/Fab';
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Slide from "@material-ui/core/Slide/Slide";
import styles from "./Dialog.module.css";
import MUIDialog from "@material-ui/core/Dialog/Dialog";
import AppBarLogo from "./AppBarLogo";

const Transition = (props) => <Slide direction='up' {...props}/>;

class Dialog extends React.PureComponent {

    constructor() {
        super();
        this.state = {
            open: false
        };
    }

    render() {
        const {children, title, isAreaDefined} = this.props;
        const {open} = this.state;
        return <>
            <Fab variant="extended" color="primary" size="medium" onClick={this.handleOpen}>
                {title}
            </Fab>
            <MUIDialog
                fullScreen
                open={open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
            >
                <AppBar position='relative'>
                    <Toolbar disableGutters>
                        <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                            <CloseIcon/>
                        </IconButton>
                        <AppBarLogo/>
                        <div className={styles.flex}/>
                        <Button color="inherit" onClick={this.handleSave} disabled={!isAreaDefined}>
                            Finish measuring
                        </Button>
                    </Toolbar>
                </AppBar>
                {children}
            </MUIDialog>
        </>
    }

    handleOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({
            open: false
        });
    };

    handleSave = () => {
        const {onSave} = this.props;
        onSave();
        this.handleClose();
    }

}

export default Dialog;
