import decodePolyline from "decode-google-map-polyline";
import referenceLocations from './ReferenceLocations'

//Reference to the formula https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
function calcDistance(lat, lon, refLat, refLon) {
    const p = 0.017453292519943295;    // Math.PI / 180
    const c = Math.cos;
    const a = 0.5 - c((refLat - lat) * p)/2 +
        c(lat * p) * c(refLat * p) *
        (1 - c((refLon - lon) * p))/2;

    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

//Reference why this calculation https://codeburst.io/javascript-finding-minimum-and-maximum-values-in-an-array-of-objects-329c5c7e22a2
function calcClosestReference(arr) {
    let minLocation = arr[0];

    for (let i = 1, len=arr.length; i < len; i++) {
        const currLocation = arr[i];
        minLocation = (currLocation.distance < minLocation.distance) ? currLocation : minLocation;
    }

    return minLocation.name;
}

/**
 * Calculates te closest reference point we have for the buildings coords.
 * @param {string} googlePolygonPath - The encoded polygon string from google.
 * @return returns the name of the location that is the closest to the buildings coords.
 */
function calculateClosestLocation(googlePolygonPath) {
    const buildingCoords = decodePolyline(googlePolygonPath)[0]; // Retrieve first point of polygon to calculate calcDistance
    const distancedReferences = referenceLocations.map(refLoc => ({
        name: refLoc.name,
        distance: calcDistance(buildingCoords.lat, buildingCoords.lng, refLoc.lat, refLoc.lon)
    }));

    return calcClosestReference(distancedReferences);
}

export {
   calculateClosestLocation,
};