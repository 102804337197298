import {TOGGLE_DARK_THEME} from '../actions/theme.action';

const initState = {
    darkTheme: localStorage.getItem("dark-theme") === null || localStorage.getItem("dark-theme") === 'true'
};

const reducer = (state = initState, action) => {
    switch (action.type) {

        case TOGGLE_DARK_THEME: {
            const darkTheme = !state.darkTheme;
            localStorage.setItem('dark-theme', darkTheme + '');
            return {
                ...state,
                darkTheme
            }
        }

        default:
            return state;
    }
};

export default reducer;
