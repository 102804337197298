const calculateOverheat = (area, { Tover_zC_I, Tover_zC_x, Tover_zC_x2, Tover_zC_upBound, Tover_mC_I, Tover_mC_x, Tover_mC_x2, Tover_mC_upBound }) => {

    const withoutCoating = calculateOverheatHours(area, Tover_zC_I, Tover_zC_x, Tover_zC_x2, Tover_zC_upBound);
    const withCoating = calculateOverheatHours(area, Tover_mC_I, Tover_mC_x, Tover_mC_x2, Tover_mC_upBound);

    const difference = withoutCoating - withCoating;
    const percentage = difference / withoutCoating;

    return {
        withoutCoating: format(withoutCoating),
        withCoating: format(withCoating),
        difference: format(difference),
        percentage: format(percentage * 100, 1)
    }
};

const calculateOverheatHours = (area, Tover_I, Tover_x, Tover_x2, Tover_upBound) => {
    const sqrtArea = Math.sqrt(area);

    if (Tover_upBound < 0 || sqrtArea < Tover_upBound || Tover_upBound === 'NA') {
        return Tover_I + Tover_x * sqrtArea + Tover_x2 * area;
    }
    return 0;
};



const s00p = {
    price: 49.95,
    liters: 5,
    area: 200
};
const calculates00p = area => {
    const buckets = Math.ceil(area / s00p.area);
    const price = buckets * s00p.price;
    return {
        price: format(price, 2),
        liters: buckets * s00p.liters
    };
};



const white2 = {
    price: 104.95,
    liters: 5,
    area: 15
};
const calculate2white = area => {
    const buckets = Math.ceil(area / white2.area);
    const price = buckets * white2.price;
    return {
        price: format(price, 2),
        liters: buckets * white2.liters
    };
};



const format = (value, precision = 0) => {
    const p = Math.pow(10, precision);
    return Math.round(value * p) / p;
};

export {
    calculateOverheat,
    calculates00p,
    calculate2white
};
